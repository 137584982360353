import React, {useEffect, useState} from 'react'
import { useLocation, useNavigate, Link  } from 'react-router-dom'
import axios from 'axios'
import { httpss } from '../hosts';
import tuoitre from '../../assets/imagesSourceNews/TuoiTre.png'
import thanhnien from '../../assets/imagesSourceNews/ThanhNien.ico'
import { FaAngleLeft } from "react-icons/fa6"
import { MdHome } from "react-icons/md"
import { AiOutlineMessage } from "react-icons/ai";
import { FiSend } from "react-icons/fi";

export default function HeaderDetail() {
    const location = useLocation()
    const navigate = useNavigate()

    const [id_news, setId_news] = useState('')
    const [news, setNews] = useState([])

    useEffect(()=>{
        const handleGetIdNews = ()=>{
            setId_news(location.pathname.split('/')[2])
        }
        handleGetIdNews()
    },[location])

    const getDetail = async (id)=>{
        try {
        const res = await axios.get(`${httpss}api/new/${id}`)
        setNews(res.data)
        
        } catch (error) {
        console.error('Lỗi:', error);
        }
    }

    useEffect(()=>{
        if(id_news){
        getDetail(id_news)
        }
    },[id_news])

    const Back = ()=>{
        navigate(-1)
    }

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
        };
        scrollToTop()
    })

    // console.log(news)

  return (
    <div>
        <div className='bg-white shadow-md w-full flex justify-between px-[20px] py-[15px] fixed mt-[-70px]'>
            <div className='w-[85px]'>
                <FaAngleLeft size={'20px'} onClick={Back} />
            </div>
            <div className='w-[85px]'>
                <img src={news.source==='thanhnien.vn'?thanhnien:news.source==='tuoitre.vn'?tuoitre:''} className={`${news.source==='thanhnien.vn'?'w-[20px]':news.source==='tuoitre.vn'?'w-[60px]':''} mx-auto`} />
            </div>
            <div className='w-[85px] flex justify-between'>
                <Link to={`/`} className='mx-auto'>
                    <MdHome size={'25px'} color='#53a84a' />
                </Link>
                {/* <AiOutlineMessage size={'18px'}/>
                <FiSend size={'18px'}/> */}
            </div>
        </div>
    </div>
  )
}
