import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import Header from './components/Header';

import Home from './pages/Home';
import Children from './pages/Children';
import Categories from "./pages/Categories";
import Footer from "./components/Footer";
import Reads from "./pages/Reads";
import Callback from './pages/callback';
import NotFound404 from './pages/notFound404';
import ResultSearch from './pages/ResultSearch';
import DetailMb from "./components/Mobile/DetailMb";
import DetailPC from "./components/PC/DetailPC";

function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/detail/:id" element={<DetailMb />}/>
          <Route path="/detail_pc/:id" element={<DetailPC />}/>
          
          <Route path="/" element={<Children />}/>
          <Route path="/myu-tintuc/:descriptionId/:baivietId" element={<Reads />}/>
          <Route path="/categories/:id" element={<Categories />}/>
          <Route path="*" element={<NotFound404 />} />

        </Routes>
        <Footer/>
      </BrowserRouter>
      </>
  );
}

export default App;
