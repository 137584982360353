import React from 'react'
import HomeMb from '../components/Mobile/HomeMb'
import HomePC from '../components/PC/HomePC'

export default function Home() {
  return (
    <div>

      <div className='md:hidden'>
        <HomeMb/>
      </div>

      <div className='max-md:hidden'>
        <HomePC/>
      </div>

    </div>
  )
}
