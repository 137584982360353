import React, {useEffect, useState} from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { httpss } from '../hosts';
import HeaderDetail from './HeaderDetail';
import {formatTimeAgo} from '../formatTime'
import { decodeHtmlEntities } from '../htmlCodeToDecode';
import { SlLike, SlDislike } from "react-icons/sl";
import { GoClockFill } from "react-icons/go";
import dataNavbar from '../dataNavbar';

export default function DetailMb() {
  const params = useParams()
  const [news, setNews] = useState([])
  const [listNews, setListNews] = useState([])
  const [dataPublish, setDataPublish] = useState([]);

  const getDetail = async (id)=>{
    try {
      const res = await axios.get(`${httpss}api/new/${id}`)
      setNews(res.data)
      
    } catch (error) {
      console.error('Lỗi:', error);
    }
  }

  useEffect(()=>{
    if(params.id){
      getDetail(params.id)
      getListNews()
    }
  },[params.id])

  const getListNews = async (id)=>{
    try {
    const res = await axios.get(`${httpss}api/news?limit=200`)
    setListNews(res.data)
    
    } catch (error) {
    console.error('Lỗi:', error);
    }
  }

  function groupByCategory(articles) {
    return articles.reduce((acc, article) => {
      const { category } = article;
  
      // Nếu chưa có danh sách cho thể loại này, tạo một danh sách mới
      if (!acc[category]) {
        acc[category] = [];
      }
  
      // Thêm bài viết vào danh sách của thể loại tương ứng
      acc[category].push(article);
      return acc;
    }, {});
}

  useEffect(()=>{
    const handleCategory = ()=>{
    
        const categorizedArticles = groupByCategory(listNews);
        
        let arr = []
        Object.keys(categorizedArticles).forEach(category => {
            // console.log(`${category}`);
            // console.log(categorizedArticles[category]);
    
            arr.push({[category]: categorizedArticles[category]})
        });
        setDataPublish(arr)
    }
    handleCategory()
    },[listNews])

  // console.log(news)

  return (
    <div className='px-[20px] mt-[70px] lg:hidden'>
        <div className='flex items-center mt-[20px]'>
          <GoClockFill/>
          <p className='ml-[6px]'>{formatTimeAgo(news.publish)}</p>
        </div>
        <h2 className='font-bold text-[20px] my-[20px]'>{decodeHtmlEntities(news.title)}</h2>
        <div className='my-[10px] text-justify' dangerouslySetInnerHTML={{ __html: news.description }}></div>
        <div className='my-[10px] text-justify' dangerouslySetInnerHTML={{ __html: news.html }}></div>
        <Link to={news.link}>
          <div className='w-full text-[12px] text-[#777] border-[1px] border-[#999] px-[8px] py-[3px] my-[10px] rounded-md'>{news.link?news.link.slice(0,55):''}....</div>
        </Link>

        {/* <div className='w-[55px] flex justify-between my-[20px]'>
          <SlLike size={'20px'} />
          <SlDislike size={'20px'}/>
        </div> */}

        <div className='w-full bg-[#f5faf7] rounded-md p-[10px] mt-[20px]'>
          <p className='font-bold pb-[10px]'>TIN LIÊN QUAN</p>

          <div>
            {dataPublish.map((data, i) => {
              return Object.keys(data).map(key => {
              const articles = data[key]
              return (
                  <div key={i}>
                      {dataNavbar.map((n, index) => {
                          return articles.map((article, articleIndex) => {
                          if (article.category === n.category) {
                              if(article.category === news.category && articleIndex < 3){
                                return (
                                    <Link to={`/detail/${article.id}`} key={articleIndex}>
                                        <div className='border-t-[1px] border-[#bbb] border-dashed mb-[0px] flex justify-between py-[20px]'>
                                            <p className='mt-[0px] w-[50%]'><strong>{decodeHtmlEntities(article.title).slice(0,50)}....</strong></p>
                                            <img src={article.feature} className='w-[45%] rounded-lg' />
                                        </div>
                                    </Link>
                                )
                              }
                          }
                          return null
                          })
                      })}
                  </div>
              )
              })
            })}
          </div>
        </div>

        <div className='flex flex-wrap my-[20px]'>
          {
            dataNavbar.map((el,i)=>(
              <Link to={`/categories/${el.category}`} key={i}>
                <p className='bg-[#f5faf7] mx-[10px] my-[5px] px-[10px] py-[5px] rounded-md'>{el.name}</p>
              </Link>
            ))
          }
        </div>
    </div>
  )
}
