import React from 'react'
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import { MdHome } from "react-icons/md";
import dataNavbar from '../PC/dataNavbar_pc';

export default function HeaderPC() {
  return (
    <div className='max-lg:hidden w-full shadow-md bg-white'>
        <div className='bg-[#f5faf7] w-full'>
            <div className='w-[1200px] mx-auto flex items-center justify-between'>
                <div className='flex items-center'>
                    <img src={logo} className='w-[90px]' />
                    <div className='relative w-[230px] ml-[20px]'>
                        <input type='text' placeholder='Nhập nội dung tìm kiếm'
                            className='w-full border-[1px] border-[#bbb] bg-[#f5faf7] rounded-md py-[5px] px-[10px] outline-none' />
                        <CiSearch size={'25px'} className='absolute top-[5px] right-[10px] cursor-pointer' />
                    </div>
                </div>

                {/* <div className='flex justify-between w-[150px] font-bold cursor-pointer'>
                    <Link to={`https://id.myu.vn/account/login`}>
                        <p>Đăng nhập</p>
                    </Link>
                    <Link to={`https://id.myu.vn/account/register`}>
                        <p>Đăng ký</p>
                    </Link>
                </div> */}
            </div>
        </div>

        <div className='w-[1200px] flex flex-wrap items-center mx-auto mt-[0px] py-[15px] bg-white'>
            <Link to={`/`}>
                <MdHome size={'28px'} color='#53a84a' className='cursor-pointer' />
            </Link>
            <ul className='flex flex-wrap items-center'>
                {
                    dataNavbar.map((el,i)=>{
                        if(el.name==='Tin tức HHH SG'){
                            return <Link to={`https://harmony.hunghau.vn/tin-tuc-su-kien/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Nông nghiệp'){
                            return <Link to={`https://nongsanviet.nongnghiep.vn/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Xăng dầu'){
                            return <Link to={`https://petrovietnam.petrotimes.vn/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Đầu tư'){
                            return <Link to={`https://baodautu.vn/dau-tu-d2/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Tài chính'){
                            return <Link to={`https://vneconomy.vn/tai-chinh.htm`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Dự án'){
                            return <Link to={`https://baodautu.vn/batdongsan/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else {
                            return <Link to={`/categories/${el.category}`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                    })
                }
            </ul>
        </div>
    </div>
  )
}
