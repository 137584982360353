import React, { useState, useEffect } from 'react';
import '../../styles/StyleNews.css';
import axios from 'axios';
import { httpss } from '../hosts';
import dataNavbar from '../dataNavbar';
import SourceImageMap from '../SourceImageMap';
import { FaAngleRight } from "react-icons/fa6";
import { GoMoveToTop } from "react-icons/go";
import logo from '../../assets/images/logo.png'
import { Link } from 'react-router-dom';
import { formatTimeAgo } from '../formatTime';
import scrollToTop from '../crollToTop'
import { decodeHtmlEntities } from '../htmlCodeToDecode';
import shop1 from '../../assets/images/shop1.png'
import shop2 from '../../assets/images/shop2.png'
import shop3 from '../../assets/images/shop3.png'
import shop4 from '../../assets/images/shop4.png'
import shop5 from '../../assets/images/shop5.png'
import shop6 from '../../assets/images/shop6.png'

export default function HomeMb() {
const [dataNews, setDataNews] = useState([]);
const [dataPublish, setDataPublish] = useState([]);

const [arr_image, setArr_image] = useState([
    shop1, shop2, shop3, shop4, shop5, shop6
])

const getDataNews = async () => {
    try {
    const res = await axios.get(`${httpss}api/news?limit=200`)
    setDataNews(res.data)

    } catch (error) {
        console.error('Lỗi:', error);
    }
};

useEffect(() => {
    getDataNews();
    localStorage.setItem('UrlBack', window.location.pathname);
    handleScrollPosition()
}, []);

  // Hàm tự động nhóm các bài viết theo thể loại
function groupByCategory(articles) {
    return articles.reduce((acc, article) => {
      const { category } = article;
  
      // Nếu chưa có danh sách cho thể loại này, tạo một danh sách mới
      if (!acc[category]) {
        acc[category] = [];
      }
  
      // Thêm bài viết vào danh sách của thể loại tương ứng
      acc[category].push(article);
      return acc;
    }, {});
}

useEffect(()=>{
const handleCategory = ()=>{

    const categorizedArticles = groupByCategory(dataNews);
    
    let arr = []
    Object.keys(categorizedArticles).forEach(category => {
        // console.log(`${category}`);
        // console.log(categorizedArticles[category]);

        arr.push({[category]: categorizedArticles[category]})
    });
    setDataPublish(arr)
}
handleCategory()
},[dataNews])

function handleChange(e){
    var main = document.getElementById("main");
    main.src = e.target.src;
}

const handleSaveScroll = () =>{
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
}
const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
        setTimeout(() => {
            window.scrollTo({top: parseInt(scrollPosition),behavior: 'smooth'});
        }, 400);
        sessionStorage.removeItem("scrollPosition");
    }
};

// useEffect(()=>{
//     const scrollToTop = () => {
//         window.scrollTo({
//           top: 0,
//           behavior: 'smooth'
//         });
//     };
//     scrollToTop()
// })

const pageStyles = {
    fontFamily: "-apple-system, sans-serif, serif",
};
// console.log(dataPublish)

return (
    <div className='px-[20px]'>
        {/* Tin mới  */}
        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'tin-moi' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                            <div className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <img src={article.feature} className='w-full' />
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                                else {
                                    return (
                                        <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                            <div className='flex justify-between my-[10px]'>
                                                <p className='w-[45%]  font-bold'>{decodeHtmlEntities(article.description).slice(0,70)}....</p>
                                                <img src={article.feature} className='w-[45%] object-cover rounded-lg' />
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {/* <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://harmony.hunghau.vn/tin-tuc-su-kien/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>TIN TỨC HHH SG</p>
                </Link>
            </div>

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>
            
        </div> */}
        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'kinh-doanh' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{'TIN TỨC HHH SG'}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'thoi-su' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {/* <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://nongsanviet.nongnghiep.vn/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>NÔNG NGHIỆP</p>
                </Link>
            </div>

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>
        </div> */}
        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'van-hoa' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{'NÔNG NGHIỆP'}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {/* <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://petrovietnam.petrotimes.vn/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>XĂNG DẦU</p>
                </Link>
            </div>

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>
        </div> */}
        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'the-gioi' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{'XĂNG DẦU'}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'giao-duc' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {/* <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://baodautu.vn/dau-tu-d2/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>ĐẦU TƯ</p>
                </Link>
            </div>

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>
        </div> */}
        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'kinh-doanh' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{'ĐẦU TƯ'}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {/* <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://vneconomy.vn/tai-chinh.htm`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>TÀI CHÍNH</p>
                </Link>
            </div>

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>
        </div> */}
        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'tin-moi' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{'TÀI CHÍNH'}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {/* <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://baodautu.vn/batdongsan/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>DỰ ÁN</p>
                </Link>
            </div>

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>
        </div> */}
        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'cong-nghe' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{'DỰ ÁN'}</p>
                                            </div>
                                            <Link onClick={handleSaveScroll} to={article.link}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {/* các mục còn lại */}
        {/* {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'thoi-su' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'kinh-doanh' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'phap-luat' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'the-thao' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'giao-duc' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'cong-nghe' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'suc-khoe' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })}

        {dataPublish.map((data, i) => {
            return Object.keys(data).map(key => {
            const articles = data[key]
            return (
                <div key={i}>
                    {dataNavbar.map((n, index) => {
                        return articles.map((article, articleIndex) => {
                        if (article.category === n.category) {
                            if(article.category === 'van-hoa' && articleIndex < 4){
                                if(articleIndex===0){
                                    return (
                                        <div key={articleIndex} className='border-b-[1px] border-[#bbb] border-dashed mb-[10px]'>
                                            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[20px]'>
                                                <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>{n.name.toUpperCase()}</p>
                                            </div>
                                            <Link to={`/detail/${article.id}`}>
                                                <img src={article.feature} className='w-full' />
                                                <p className='mt-[10px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </Link>
                                        </div>
                                    )
                                }
                                else {
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={`/detail/${article.id}`}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                        }
                        return null
                        })
                    })}
                </div>
            )
            })
        })} */}

        <div className='bg-[#f5faf7] rounded-md p-[10px]'>
            <img src={logo} className='w-[100px] mx-auto' />
            <p className='font-bold text-center my-[15px]'>DEAL HOT TẠI MYU SHOP</p>
            <div className="no-wrap max-sm:overflow-x-scroll w-full sm:flex sm:justify-center">
                <div className="flex w-max overflow-hidden">
                    {
                        arr_image.map((el,i)=>{
                            if(i===2){
                                return <Link to={`https://myu.vn/shop/danh-muc?rootCategoryId=34`} key={i}>
                                    <img
                                        className="w-[200px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                                        src={el}
                                        alt=""
                                        // onClick={(e) => handleChange(e)}
                                    />
                                </Link>
                            }
                            else if(i===4){
                                return <Link to={`https://myu.vn/shop`} key={i}>
                                    <img
                                        className="w-[200px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                                        src={el}
                                        alt=""
                                        // onClick={(e) => handleChange(e)}
                                    />
                                </Link>
                            }
                            else if(i===5){
                                return <Link to={`https://myu.vn/shop/danh-muc?rootCategoryId=4`} key={i}>
                                    <img
                                        className="w-[200px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                                        src={el}
                                        alt=""
                                        // onClick={(e) => handleChange(e)}
                                    />
                                </Link>
                            }
                            else {
                                return <Link to={`https://myu.vn/`} key={i}>
                                    <img 
                                        className="w-[200px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                                        src={el}
                                        alt=""
                                        // onClick={(e) => handleChange(e)}
                                    />
                                </Link>
                            }
                            // <img key={i}
                            //     className="w-[200px] aspect-video bg-blue-500 rounded-lg cursor-pointer mr-2" 
                            //     src={el}
                            //     alt=""
                            //     onClick={(e) => handleChange(e)}
                            // />
                        })
                    }
                </div>
            </div>
        </div>

        <div className='w-full h-[130px] bg-[#f5faf7] rounded-md p-[10px] my-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <p className='mx-[5px] font-bold mt-[3px]'>Video HHH SG</p>
            </div>
            <p className='text-center text-[#bbb] text-[25px]'>COMING SOON</p>
        </div>
    </div>
  )
}
