import LaoDong from '../assets/imagesSourceNews/LaoDong.png';
import TienPhong from '../assets/imagesSourceNews/TienPhong.png';
import TuoiTre from '../assets/imagesSourceNews/TuoiTre.png';
import BaoGiaoThong from '../assets/imagesSourceNews/BaoGiaoThong.png';
import VnExpress from '../assets/imagesSourceNews/VnExpress.png';
import VTCNews from '../assets/imagesSourceNews/VTCNews.png';
import ThanhNien from '../assets/imagesSourceNews/ThanhNien.ico';
import DanTri from '../assets/imagesSourceNews/DanTri.png';
import CafeBiz from '../assets/imagesSourceNews/cafeBiz.png';
import NgoiSao from '../assets/imagesSourceNews/NgoiSao.svg';
import QuanDoiNhanDan from '../assets/imagesSourceNews/QuanDoiNhanDan.jpg';
import PhapLuat from '../assets/imagesSourceNews/PhapLuat.png'
import DanViet from '../assets/imagesSourceNews/DanViet.webp'
import NguoiLaoDong from '../assets/imagesSourceNews/NguoiLaoDong.webp'
import Soha from '../assets/imagesSourceNews/Soha.webp'
import ZNews from '../assets/imagesSourceNews/Znews.png'
import NhanDan from '../assets/imagesSourceNews/NhanDan.png'
import VnEconomy from '../assets/imagesSourceNews/VnEconomy.png'
import DocNhanh from '../assets/imagesSourceNews/DocNhanh.jpg'
import KinhTeDoThi from '../assets/imagesSourceNews/KinhTeDoThi.png'

const SourceImageMap = {
    'laodong.vn': {name: "Lao Động",img: LaoDong},
    'dantri.com.vn': {name: "Dân Trí",img: DanTri},
    'thanhnien.vn': {name: "Thanh Niên",img: ThanhNien}, 
    'tienphong.vn': {name: "Tiền Phong",img: TienPhong},
    'tuoitre.vn': {name: "Tuổi Trẻ",img: TuoiTre},
    'www.baogiaothong.vn': {name: "Báo Giao Thông",img: BaoGiaoThong},
    'vnexpress.net': {name: "VnExpress",img: VnExpress},
    'vtc.vn': {name: "VTC News",img: VTCNews},
    'cafebiz.vn': {name: "CafeBiz",img: CafeBiz},
    'ngoisao.vnexpress.net': {name: "Ngôi Sao",img: NgoiSao},
    'www.qdnd.vn': {name: "Quân Đội Nhân Nhân",img: QuanDoiNhanDan},
    'plo.vn': {name: "Pháp Luật",img: PhapLuat},
    'danviet.vn': {name: "Dân Việt",img: DanViet},
    'nld.com.vn': {name: "Người Lao Động",img: NguoiLaoDong},
    'soha.vn': {name: "Soha",img: Soha},
    'znews.vn': {name: "Z News",img: ZNews},
    'nhandan.vn': {name: "Nhân Dân", img: NhanDan},
    'vneconomy.vn': {name: "VnEconomy", img: VnEconomy},
    'docnhanh.vn': {name: "Đọc Nhanh Online", img: DocNhanh},
    'kinhtedothi.vn': {name: "Kinh tế & Đô thị", img: KinhTeDoThi},
};

export default SourceImageMap;
