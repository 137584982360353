import React,{ useState, useEffect } from "react";
import { FaSearch } from "react-icons/fa";

import { IoIosClose, IoIosMenu } from "react-icons/io";
import {  IoMenu } from "react-icons/io5";
import { Link } from "react-router-dom";

import { AiFillHome } from "react-icons/ai";
import { RiSearch2Line, RiUserLine, RiMenuLine } from "react-icons/ri";

import Logo from "../assets/images/logo.png"
import LoginForm from './LoginForm'
import { httpss } from "./hosts";
import dataNavbar from './PC/dataNavbar_pc'
import HeaderDetail from "./Mobile/HeaderDetail";
import { useLocation } from 'react-router-dom';
import HeaderPC from "./PC/HeaderPC";

export default function Header() {
    const [search, setSearch] = useState(false);
    const [searchInput, setSearchInput] = useState('');
    const [menu, setMenu] = useState(false);

    const [checkHeader, setCheckHeader] = useState(false)
    const location = useLocation()

    useEffect(()=>{
        const handleHeader = ()=>{
            if(location.pathname.split('/')[1] === 'detail'){
                setCheckHeader(true)
            }
            else {
                setCheckHeader(false);
            }
        }
        handleHeader()
    },[location])

    const handleInputSearch = (e) => {const dataSearch = e.target.value;setSearchInput(dataSearch)}

    const handleSearch = async () => {
        try {
            const url = `https://apinews.myu.vn/search?title=${searchInput}`;
            const response = await fetch(url, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
            });
            if (response.ok) {
                const responseData = await response.json();
                // console.log(responseData)
                window.location.href = `/Result/${searchInput}`
            } else {
                console.error('Request failed:', response.status);
            }
        } catch (error) {
            console.error('Lỗi:', error);
        }
    };


    const handleOpenMenu = () => {
        setSearch(false);
        setMenu(!menu)
    }
    const handleOpenSearch = () => {
        setMenu(false);
        setSearch(!search)
    }

    return (
        <>
        {
            checkHeader ?
            <HeaderDetail/>
            :
            <div className="z-20 sticky top-0 ">
                {/* <div>
                    img Quảng Cáo
                </div> */}

                {/* Giao diện header PC */}
                <HeaderPC/>


                {/* Giao diện header mobile */}
                <div className="md:hidden">
                    <div className="bg-[#F5FAF7] shadow">
                        <div className="flex justify-between items-center mx-5 ">
                            <div> {/* Nút Menu header */}
                                <button onClick={handleOpenMenu} className="text-xl p-2 rounded-lg">
                                    {menu ? (
                                        <div className="bg-[#49A947] text-white p-2 rounded-lg">
                                            <IoIosClose/>
                                        </div>
                                    ) : (
                                        <RiMenuLine/>
                                    )}
                                </button>
                            </div>
                            <div><button className="text-2xl text-[#49A947] p-2"><Link to={'/'}><AiFillHome/></Link></button></div>
                            <div><img src={Logo} className="h-14" alt='Myu Logo'/></div>
                            <div>  {/* Nút Tìm kiếm header */}
                                <button onClick={handleOpenSearch} className="text-xl p-2 rounded-lg">
                                {search ? (
                                        <div className="bg-[#49A947] text-white p-2 rounded-lg">
                                            <IoIosClose/>
                                        </div>
                                    ) : (
                                        <RiSearch2Line/>
                                    )}
                                </button>
                            </div>
                            <div><button className="text-xl hover:text-white hover:bg-[#49A947] p-2 rounded-lg"><RiUserLine/></button></div>
                        </div>
                    </div>
                    {search && 
                        <div className="mx-2 shadow rounded-md flex items-center md:hidden bg-gray-100">
                            <input type="search"
                                className="p-2 w-full outline-none rounded-md bg-gray-100"
                                placeholder="Tìm kiếm" />
                            <button className="mx-5 text-blue-900">
                                <FaSearch/>
                            </button>
                        </div>
                    }
                    
                    {menu && 
                    <div className="fixed bg-black/10 rounded-b-lg shadow-xl overflow-auto scroll h-fit max-md:h-[70vh] mx-5 ">
                        <div onClick={handleOpenMenu} className="grid grid-cols-5 max-xl:grid-cols-4 max-lg:grid-cols-3 max-md:grid-cols-2 max-sm:grid-cols-1 gap-2 my-2">
                            {Array.isArray(dataNavbar) && dataNavbar.map((item, index) => {
                                if(item.name!=='Tin tức HHH SG'){
                                    return <Link key={index} to={`/categories/${item.category}`} className="hover:shadow-xl hover:bg-blue-400 hover:underlin hover:text-white shadow bg-white rounded-md py-2 px-5 mx-2">
                                        <p>{item.name}</p>
                                    </Link>
                                }
                                else {
                                    return <Link key={index} to={`https://harmony.hunghau.vn/tin-tuc-su-kien/`} className="hover:shadow-xl hover:bg-blue-400 hover:underlin hover:text-white shadow bg-white rounded-md py-2 px-5 mx-2">
                                        <p>{item.name}</p>
                                    </Link>
                                }
                            })}
                        </div>
                    </div>
                    }
                </div>
            </div>
        }
        </>
    )
}
