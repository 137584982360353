import React, {useEffect, useState} from 'react'
import { useParams, Link } from 'react-router-dom'
import axios from 'axios'
import { httpss } from '../hosts';
import {formatTimeAgo} from '../formatTime'
import { decodeHtmlEntities } from '../htmlCodeToDecode';
import { SlLike, SlDislike } from "react-icons/sl";
import { GoClockFill } from "react-icons/go";
import dataNavbar from '../dataNavbar';
import dataNavbar2 from '../PC/dataNavbar_pc';
import tuoitre from '../../assets/imagesSourceNews/TuoiTre.png'
import thanhnien from '../../assets/imagesSourceNews/ThanhNien.ico'

export default function DetailPC() {
    const params = useParams()
    const [news, setNews] = useState([])
    const [listNews, setListNews] = useState([])
    const [dataPublish, setDataPublish] = useState([]);

    const getDetail = async (id)=>{
        try {
          const res = await axios.get(`${httpss}api/new/${id}`)
          setNews(res.data)
          
        } catch (error) {
          console.error('Lỗi:', error);
        }
    }
    
    useEffect(()=>{
    if(params.id){
        getDetail(params.id)
        getListNews()
    }
    },[params.id])

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        };
        scrollToTop()
    })

    const getListNews = async (id)=>{
        try {
        const res = await axios.get(`${httpss}api/news?limit=200`)
        setListNews(res.data)
        
        } catch (error) {
        console.error('Lỗi:', error);
        }
      }
    
    function groupByCategory(articles) {
        return articles.reduce((acc, article) => {
          const { category } = article;
      
          // Nếu chưa có danh sách cho thể loại này, tạo một danh sách mới
          if (!acc[category]) {
            acc[category] = [];
          }
      
          // Thêm bài viết vào danh sách của thể loại tương ứng
          acc[category].push(article);
          return acc;
        }, {});
    }
    
    useEffect(()=>{
        const handleCategory = ()=>{
        
            const categorizedArticles = groupByCategory(listNews);
            
            let arr = []
            Object.keys(categorizedArticles).forEach(category => {
                // console.log(`${category}`);
                // console.log(categorizedArticles[category]);
        
                arr.push({[category]: categorizedArticles[category]})
            });
            setDataPublish(arr)
        }
        handleCategory()
    },[listNews])

    console.log(news)

  return (
    <div className='w-[1200px] mx-auto'>
        <div className='w-full flex justify-between'>
            <div className='w-[60%]'>
                <div className='flex items-center mt-[20px] mb-[10px]'>
                    {
                        dataNavbar.map((el,i)=>{
                            if(el.category===news.category){
                                return <p className='mr-[10px] font-bold text-[18px]'>{el.name} |</p>
                            }
                        })
                    }
                    <div className='flex items-center'>
                        <GoClockFill/>
                        <p className='ml-[6px]'>{formatTimeAgo(news.publish)}</p>
                    </div>
                </div>
                <p className='font-bold text-[22px]'>{decodeHtmlEntities(news.title)}</p>
                {
                    news.source==='tuoitre.vn' ?
                    <img src={tuoitre} className='w-[100px] my-[10px]' />
                    :
                    <img src={thanhnien} className='w-[30px] my-[10px]' />
                }
                <div className='my-[10px] text-justify' dangerouslySetInnerHTML={{ __html: news.description }}></div>
                <div className='my-[10px] text-justify' dangerouslySetInnerHTML={{ __html: news.html }}></div>
                <Link to={news.link}>
                    <div className='w-full text-[13px] text-[#777] border-[1px] border-[#999] px-[8px] py-[3px] my-[10px] rounded-md'>{news.link?news.link:''}</div>
                </Link>
            </div>

            <div className='w-[39%] mt-[20px]'>
                {dataPublish.map((data, i) => {
                    return Object.keys(data).map(key => {
                    const articles = data[key]
                    return (
                        <div key={i}>
                            {dataNavbar.map((n, index) => {
                                return articles.map((article, articleIndex) => {
                                if (article.category === n.category) {
                                    if(article.category === news.category){
                                        if(articleIndex < 3 && article.id !== news.id){
                                            return (
                                                <Link to={`/detail_pc/${article.id}`} key={articleIndex}>
                                                    <div className='border-b-[1px] border-[#bbb] mb-[10px]'>
                                                        <img src={article.feature} />
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    }
                                }
                                return null
                                })
                            })}
                        </div>
                    )
                    })
                })}
            </div>
        </div>

        <div className='w-full bg-[#f5faf7] px-[30px] py-[20px] rounded-md mt-[20px]'>
            <p className='text-[20px] font-bold mb-[10px]'>TIN LIÊN QUAN</p>
            <div>
            {dataPublish.map((data, i) => {
                    return Object.keys(data).map(key => {
                    const articles = data[key]
                    return (
                        <div key={i} className='flex justify-between'>
                            {dataNavbar.map((n, index) => {
                                return articles.map((article, articleIndex) => {
                                if (article.category === n.category) {
                                    if(article.category === news.category){
                                        if(articleIndex >= 4 && articleIndex < 8 && article.id !== news.id){
                                            return (
                                                <Link to={`/detail_pc/${article.id}`} key={articleIndex}>
                                                    <div className='w-[270px] mb-[10px]'>
                                                        <img src={article.feature} className='w-[270px] h-[140px] object-cover' />
                                                        <p className='font-bold mt-[10px]'>{decodeHtmlEntities(article.title)}</p>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    }
                                }
                                return null
                                })
                            })}
                        </div>
                    )
                    })
                })}
            </div>
        </div>

        <div className='w-full bg-[#f5faf7] px-[30px] py-[20px] rounded-md mt-[20px]'>
            <p className='text-[20px] font-bold mb-[10px]'>XEM THÊM</p>
            <div>
                {dataPublish.map((data, i) => {
                    return Object.keys(data).map(key => {
                    const articles = data[key]
                    return (
                        <div key={i} className='flex justify-between'>
                            {dataNavbar.map((n, index) => {
                                return articles.map((article, articleIndex) => {
                                if (article.category === n.category) {
                                    if(article.category === 'tin-moi'){
                                        if(articleIndex < 4){
                                            return (
                                                <Link to={`/detail_pc/${article.id}`} key={articleIndex}>
                                                    <div className='w-[270px] mb-[10px]'>
                                                        <img src={article.feature} className='w-[270px] h-[140px] object-cover' />
                                                        <p className='font-bold mt-[10px]'>{decodeHtmlEntities(article.title)}</p>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    }
                                }
                                return null
                                })
                            })}
                        </div>
                    )
                    })
                })}

                {dataPublish.map((data, i) => {
                    return Object.keys(data).map(key => {
                    const articles = data[key]
                    return (
                        <div key={i} className='flex justify-between'>
                            {dataNavbar.map((n, index) => {
                                return articles.map((article, articleIndex) => {
                                if (article.category === n.category) {
                                    if(article.category === 'tin-moi'){
                                        if(articleIndex >= 4 && articleIndex < 8){
                                            return (
                                                <Link to={`/detail_pc/${article.id}`} key={articleIndex}>
                                                    <div className='w-[270px] mb-[10px]'>
                                                        <img src={article.feature} className='w-[270px] h-[140px] object-cover' />
                                                        <p className='font-bold mt-[10px]'>{decodeHtmlEntities(article.title)}</p>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    }
                                }
                                return null
                                })
                            })}
                        </div>
                    )
                    })
                })}
            </div>
        </div>

        <div className='w-[1200px] flex flex-wrap items-center mx-auto mt-[0px] py-[20px] bg-white'>
            {/* <MdHome size={'28px'} color='#53a84a' className='cursor-pointer' /> */}
            <ul className='flex flex-wrap items-center'>
                {
                    dataNavbar2.map((el,i)=>(
                        <Link to={`/categories/${el.category}`} key={i}>
                            <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[9px] text-[14px] font-bold cursor-pointer hover:text-[#53a84a]`}>{el.name}</li>
                        </Link>
                    ))
                }
            </ul>
        </div>
    </div>
  )
}
