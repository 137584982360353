export function formatTimeAgo(publish) {
    const now = new Date();
    const then = new Date(publish);
    const diff = Math.floor((now - then) / 1000); 
    if (diff < 60) {
        return `${diff} giây trước`;
    } else if (diff < 3600) {
        return `${Math.floor(diff / 60)} phút trước`;
    } else if (diff < 86400) {
        return `${Math.floor(diff / 3600)} giờ trước`;
    } else {
        return "";
    }
}
