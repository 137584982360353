import React,{useState,useEffect} from 'react'
import { useParams, Link } from 'react-router-dom';
import dataNews from '../assets/News.json'
// import dataTitleNews from '../utils/Categories.json'
import dataNavbar from '../components/dataNavbar';
import SourceImageMap from '../components/SourceImageMap';
import { FaRegNewspaper } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import { IoIosCheckmark } from "react-icons/io";
import { MdOutlineSource } from "react-icons/md";
import { BsThreeDots } from "react-icons/bs";
import { GoMoveToTop } from "react-icons/go";
import '../styles/CategoriesStyle.css'
import { httpss } from '../components/hosts';
import {decodeHtmlEntities} from '../components/htmlCodeToDecode'
import { formatTimeAgo } from '../components/formatTime';
import scrollToTop from '../components/crollToTop'
// import img from '../assets/images_Categories/chinh-tri.jpg'

export default function Categories() {

    let { id } = useParams();
    const [dataNews, setDataNews] = useState('')
    const [dataNewest, setDataNewest] = useState('')
    const [filteredNews, setFilteredNews] = useState([]);
    const [isFilteredNews, setIsFilteredNews] = useState(false)
    const [currentFilter, setCurrentFilter] = useState(null);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [displayedItems, setDisplayedItems] = useState(20)

    const getData = async () => {
        try {
        const url = `${httpss}api/news?category=${id}&limit=200`;
          const response = await fetch(url, {
              method: 'GET',
              headers: {'Content-Type': 'application/json',},});
          if (response.ok) {
              const responseData = await response.json();
              setDataNews(responseData);
            //   console.log(responseData)
              } else {console.error('Request failed:', response.status);
            //   alert('Đã xảy ra lỗi, bạn kiểm tra lại kết nối mạng hoặc tải lại trang nhé');
            }
        } catch (error) {console.error('Lỗi:', error);}};

        const getDataNewest = async () => {
            try {
                const url = `${httpss}api/news?&limit=3`;
                const response = await fetch(url, {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                });
                if (response.ok) {
                    const responseData = await response.json();
                    setDataNewest(responseData);
                } else {
                    console.error('Request failed:', response.status);
                    // alert('Đã xảy ra lỗi, bạn kiểm tra lại kết nối mạng hoặc tải lại trang nhé');
                }
            } catch (error) {console.error('Lỗi:', error);}};

        useEffect(() => {
            getData();
            getDataNewest();
            localStorage.setItem('UrlBack', window.location.pathname);
        }, [id]);

        const handleFilterNews = async (e) => {
            const source = e.target.value;
            if (currentFilter === source) {
                setCurrentFilter(null);
                getData();
            } else {
                try {
                    const url = `${httpss}api/news?category=${id}&source=${source}&limit=200`;
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: { 'Content-Type': 'application/json' },
                    });
                    if (response.ok) {
                        const responseData = await response.json();
                        setDataNews(responseData);
                        setCurrentFilter(source);
                    } else {
                        console.error('Request failed:', response.status);
                    }
                } catch (error) {
                    console.error('Error:', error);
                }
            }
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        };

    const handleOpenMenuMobile = (e) => {
        setIsMenuOpen(!isMenuOpen);
        if (e.target.id === 'form') {
            setIsMenuOpen(!isMenuOpen);
        }
    };

    function extractTextFromHTML(htmlString) {
        const tempDiv = document.createElement('div');
        tempDiv.innerHTML = htmlString;
        return tempDiv.textContent || tempDiv.innerText || "";
      }

    // const handleLoadMore = () => {setDisplayedItems(displayedItems + 50)}

    useEffect(() => {
        const handleScroll = () => {
            const innerHeightBottom = Math.floor(document.documentElement.offsetHeight);
            const innerHeightScroll = Math.floor(window.innerHeight + document.documentElement.scrollTop);
            const threshold = 200;

            if (innerHeightBottom - innerHeightScroll <= threshold) {
                setDisplayedItems(itemDisplay => itemDisplay + 20);
            }
        };
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(()=>{
        const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            });
        };
        scrollToTop()
    })
    

  return (
    <div className='container mx-auto'>
        <div className='flex justify-center'>
            {/* Trái */}
            <div className='z-0 sticky top-[128px] h-screen w-[240px] m-5 max-lg:hidden'>
                    <div className='border shadow rounded-xl p-2 w-[240px] mt-8'>
                    {Array.isArray(dataNavbar)&&dataNavbar.filter(newsItem => newsItem.category===id).map((item,index)=>(
                        <div>
                            <div className='flex justify-center items-end h-[5vh]'>
                                <img src={ require(`../assets/images_Categories/${item.category}.jpg`) } alt="" className='w-20 h-20 rounded-xl object-cover'/>
                            </div>
                            <p className='text-xl my-5 font-bold text-center text-gray-700'>{item.name}</p>
                        </div>
                        ))}
                    </div>
                    {/* Đăng nhập PC */}
                {/* { login ?(
                    <button onClick={handleLogin} className='w-full bg-blue-500 hover:bg-blue-500/90 text-white rounded-xl h-12 flex items-center justify-center my-5 text-xl hover:font-bold shadow hover:shadow-xl'>
                        Đăng Nhập
                    </button>
                ):( */}
                    <div className='border shadow rounded-xl my-5 w-full overflow-y-auto h-[250px]'>
                        <div className='flex z-0 sticky top-0 bg-white shadow items-center text-gray-500 text-sm font-bold p-2'>
                            <MdOutlineSource/>
                            <p className='ml-1'>Nguồn đề xuất</p>
                        </div>
                        {Object.keys(SourceImageMap).map((item, index) => (
                            <div key={index} className='flex justify-between items-center text-gray-700 hover:bg-gray-100 rounded w-full p-2'>
                                <div className='w-6 mr-1'>
                                    <img src={SourceImageMap[item].img} alt={SourceImageMap[item].img} className='' />
                                </div>
                                <button onClick={handleFilterNews} value={item} className='w-full text-sm flex justify-start'>
                                    {SourceImageMap[item].name}
                                </button>
                                {/* <button>
                                    <CiCirclePlus className='text-3xl hover:bg-blue-500 rounded-full hover:text-white' />
                                </button>
                                <IoIosCheckmark className='text-3xl hover:bg-blue-500 rounded-full hover:text-white hidden' /> */}
                            </div>
                        ))}
                    </div>
                {/* )} */}

            </div>
            {/* nút filter mobile */}
            <div className={isMenuOpen?`hidden`:`fixed bottom-0 right-0 m-[1%] md:hidden`}>
                <button onClick={handleOpenMenuMobile} className='h-10 w-10 bg-green-500 text-white text-3xl rounded-full flex justify-center items-center'>
                    <BsThreeDots/>
                </button>
            </div>
            {isMenuOpen?(
                <div id="form" className='z-0 w-full h-full fixed top-1/2 right-1/2 transform translate-x-1/2 -translate-y-1/2 bg-gray-200/90' onClick={handleOpenMenuMobile}>
                    <div className='fixed bottom-[5%] left-[5%] right-[5%]'>
                        <div className='border shadow rounded-xl p-2 bg-white'>
                                {/* <div className='flex justify-center items-end h-[5vh]'>
                                    <img src='https://source.unsplash.com/random' alt="" className='w-20 h-20 rounded-xl'/>
                                </div> */}
                                {Array.isArray(dataNavbar)&&dataNavbar.filter(newsItem => newsItem.category===id).map((item,index)=>(
                                    <div>
                                        <div className='flex justify-center items-end h-[5vh]'>
                                            <img src={ require(`../assets/images_Categories/${item.category}.jpg`) } alt="" className='w-20 h-20 rounded-xl object-cover'/>
                                        </div>
                                        <p className='text-xl my-5 font-bold text-center text-gray-700'>{item.name}</p>
                                    </div>
                                ))}
                        </div>
                            {/* Đăng nhập mobile */}
                        {/* {login ?(
                            <button onClick={handleLogin} className=' bg-blue-500 hover:bg-blue-500/90 text-white rounded-xl h-12 flex items-center justify-center my-5 text-xl hover:font-bold shadow hover:shadow-xl w-full'>
                                Đăng Nhập
                            </button>
                        ):( */}
                            <div className=' bg-white border shadow rounded-xl my-5 overflow-y-auto h-[40vh]'>
                                <div className='flex z-0 sticky top-0 bg-white items-center text-gray-500 text-sm font-bold shadow p-2'>
                                    <MdOutlineSource/>
                                    <p className='ml-1'>Nguồn đề xuất</p>
                                </div>
                                {Object.keys(SourceImageMap).map((item, index) => (
                                    <div key={index} className='flex justify-between items-center text-gray-700 hover:bg-gray-100 rounded w-full p-2'>
                                        <div className='w-7 mr-1'>
                                            <img src={SourceImageMap[item].img} alt={SourceImageMap[item].img} className='' />
                                        </div>
                                        <button onClick={handleFilterNews} value={item} className='w-full flex justify-start'>
                                            {SourceImageMap[item].name}
                                        </button>
                                    </div>
                                ))}
                            </div>
                        {/* )} */}
                        <div className='flex justify-end'>
                            <button onClick={scrollToTop} className='h-10 w-10 bg-green-500 text-white text-3xl rounded-full flex justify-center items-center'>
                                <GoMoveToTop/>
                            </button>
                        </div>
                    </div>
                </div>
            ):(null)}

            {/* Giữa */}
            <div className='w-full mx-md:p-2'>
                <div className='lg:hidden'>
                    {Array.isArray(dataNavbar)&&dataNavbar.filter(newsItem => newsItem.category===id).map((item,index)=>(
                        <p className='text-xl my-5 font-bold text-center text-gray-700'>{item.name}</p>
                    ))}
                </div>
                {Array.isArray(isFilteredNews ? filteredNews : dataNews) && (isFilteredNews ? filteredNews : dataNews).length > 0 ? (
                    (isFilteredNews ? filteredNews : dataNews).slice(0, displayedItems).map((item, index) => (
                        <div>
                            {/* Giao diện card news mid PC */}
                            <div className='max-md:hidden cardNewsCategories w-full'>
                                <Link key={index} to={item.link} className='flex justify-between hover:shadow-xl rounded-xl my-5 h-[170px] w-full border'>
                                    <div className='flex flex-col justify-between m-2 text-gray-700'>
                                        {SourceImageMap[item.source] && (
                                            <div className='flex'>
                                                <img src={SourceImageMap[item.source].img} alt={SourceImageMap[item.source].img} className="max-w-12 h-4 mr-1" />
                                                <p className='text-sm font-bold'>{SourceImageMap[item.source].name}</p>
                                                <p className='text-sm ml-1'>{formatTimeAgo(item.publish)}</p>
                                            </div>
                                        )}
                                        <p className='text-xl font-bold text-justify '>{decodeHtmlEntities(item.title)}</p>
                                        {/* <p className='text-xl font-bold text-justify '>{item.title}</p> */}
                                        {/* <div dangerouslySetInnerHTML={{ __html: item.description }} className='truncate text-wrap text-justify'></div> */}
                                        <p className='truncate text-wrap text-justify'>{extractTextFromHTML(item.description)}</p>
                                    </div>
                                    <img src={item.feature} className='rounded-xl h-[135px] min-w-[235px] object-cover m-3 p-1'/>
                                </Link>
                            </div>
                            {/* Giao diện card news mobile */}
                            <Link key={index} to={item.link} className='md:hidden'>
                                <div className='flex flex-col justify-center rounded-xl border m-2 shadow-xl text-gray-700'>
                                    <img src={item.feature} className='rounded-xl object-cover'/>
                                    <div className='flex flex-col justify-between m-2'>
                                        {SourceImageMap[item.source] && (
                                            <div className='flex'>
                                                <img src={SourceImageMap[item.source].img} alt={SourceImageMap[item.source].img} className="max-w-12 h-4 mr-1" />
                                                <p className='text-sm font-bold'>{SourceImageMap[item.source].name}</p>
                                                <p className='text-sm ml-1'>{formatTimeAgo(item.publish)}</p>
                                            </div>
                                        )}
                                        <p className='text-xl font-bold py-5'>{decodeHtmlEntities(item.title)}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))
                ) : (
                    <div className='h-screen text-gray-700'>
                        <div className='flex items-center justify-center hover:shadow-xl rounded-xl px-2 h-[170px] mx-[2%] border text-2xl font-bold md:hidden'>Không tìm thấy dữ liệu </div>
                        <div className="flex items-center justify-center hover:shadow-xl rounded-xl px-2 my-5 h-[170px] w-full border text-3xl font-bold max-md:hidden">Không tìm thấy dữ liệu</div>
                    </div>
                )}
            </div>

            {/* Phải */}
            <div className='z-0 sticky top-[128px] h-screen w-[300px] m-5 max-xl:hidden'>
                <div className='border shadow rounded-xl p-2 w-[300px]'>
                    <div className='flex items-center text-sm font-bold text-gray-500'>
                        <FaRegNewspaper/>
                        <p className='mx-1'>Tin mới nhất</p>
                    </div>
                    {Array.isArray(dataNewest) && dataNewest.slice(0,3).map((item, index) => (
                    <div className='cardNewsTop'>
                        <Link key={index} to={`/myu-tintuc/${item.category}/${item.id}`} className='flex justify-center my-5'>
                            <div className=''>
                                <div className='flex text-sm items-center'>
                                    <p className='mr-1'>{index+1+"."}</p>
                                    {SourceImageMap[item.source]&&(
                                        <div className='flex items-center'>
                                            <img src={SourceImageMap[item.source].img} alt={SourceImageMap[item.source].img} className="max-h-3 mr-1" />
                                            <p>{SourceImageMap[item.source].name}</p>
                                        </div>
                                    )}
                                </div>
                                <p className='font-bold'>{decodeHtmlEntities(item.title.length>50?item.title.slice(0,60)+"...":item.title.slice(0,60))}</p>
                            </div>
                            <img src={item.feature} className='rounded w-16 h-16 object-cover'/>
                        </Link>
                    </div>
              ))}
                </div>
            </div>
        </div>
        <div className='flex justify-center mb-10'>
            {/* <button onClick={handleLoadMore} className='text-2xl max-md:text-xl px-5 py-2 bg-blue-500 text-white font-bold rounded-xl hover:bg-blue-500/80'>Xem thêm</button> */}
        </div>
        <div className='fixed bottom-[5%] right-[2%] max-md:hidden'>
                <button onClick={scrollToTop} className="h-10 w-10 bg-green-500/50 hover:bg-green-500 text-white hover:text-white text-2xl rounded-full flex justify-center items-center">
                    <GoMoveToTop/>
                </button>
            </div>
    </div>
  )
}
