import React, { useEffect, useState } from 'react'
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { httpss } from '../components/hosts';
import '../styles/styleReads.css'
import { decodeHtmlEntities } from '../components/htmlCodeToDecode';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { GoMoveToTop } from "react-icons/go";
import scrollToTop from '../components/crollToTop';

export default function Reads() {
  const UrlBack = localStorage.getItem("UrlBack");
  const { descriptionId, baivietId } = useParams();
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate()

  const getData = async () => {
    try {
      const url = `${httpss}api/news?category=${descriptionId}&limit=200`;
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const responseData = await response.json();
        const matchedArticle = responseData.find(item => item.id === baivietId);
        setArticle(matchedArticle);
        setLoading(false);
        console.log(responseData)
      } else {
        console.error('Request failed:', response.status);
        setError('Failed to fetch data');
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred while fetching data');
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, [descriptionId, baivietId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!article) {
    return <div>No article found</div>;
  }

  const handleBack = () => {navigate(UrlBack)};

  return (
    <div className='container mx-auto max-md:px-2'>

      {/* <button onClick={handleBack} className=" bg-blue-400/70 fixed z-10 hover:bg-blue-500/80 m-2 p-2 text-white text-xs font-bold rounded-md">
        <span className="hidden md:inline">Quay lại</span>
        <IoMdArrowRoundBack className='md:hidden'/>
      </button> */}

      <div>
        <p className='text-3xl max-md:text-xl font-bold text-center uppercase p-5'>{decodeHtmlEntities(article.title)}</p>
        <div className='text-wrap text-justify flex flex-col justify-center pb-5'>
          <div className='styleS' dangerouslySetInnerHTML={{ __html: article.html }} />
        </div>
      </div>

      <div className='fixed bottom-[5%] left-[2%] lg:hidden'>
          <button onClick={handleBack} className="h-10 w-10 bg-green-500/50 hover:bg-green-500 text-white hover:text-white text-2xl rounded-full flex justify-center items-center">
              <IoMdArrowRoundBack/>
          </button>
      </div>

      <div className='fixed bottom-[5%] right-[2%] lg:hidden'>
          <button onClick={scrollToTop} className="h-10 w-10 bg-green-500/50 hover:bg-green-500 text-white hover:text-white text-2xl rounded-full flex justify-center items-center">
              <GoMoveToTop/>
          </button>
      </div>
    </div>
  );
}
