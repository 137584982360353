module.exports = [
    {stt:"12",  name:"Tin mới nhất",     category:"tin-moi",     navbar:"1", home:"1", catgegory:"1"},
    {stt:"13",  name:"Tin tức HHH SG",     category:"tin-tuc-hhh-sg",     navbar:"1", home:"1", catgegory:"1"},
    {stt:"8",   name:"Thời sự",     category:"thoi-su",     navbar:"1", home:"1", catgegory:"1"},
    {stt:"14",  name:"Nông nghiệp",          category:"nong-nghiep",          navbar:"1", home:"1", catgegory:"1"},
    {stt:"15",  name:"Xăng dầu",          category:"xang-dau",          navbar:"1", home:"1", catgegory:"1"},
    {stt:"2",   name:"Giáo dục",    category:"giao-duc",    navbar:"1", home:"1", catgegory:"1"},
    {stt:"11",  name:"Đầu tư",          category:"dau-tu",          navbar:"1", home:"1", catgegory:"1"},
    {stt:"10",  name:"Tài chính",    category:"tai-chinh",    navbar:"1", home:"1", catgegory:"1"},
    {stt:"9",   name:"Dự án",    category:"du-an",    navbar:"1", home:"1", catgegory:"1"},
    {stt:"3",   name:"Công nghệ",   category:"cong-nghe",   navbar:"1", home:"1", catgegory:"1"},
    {stt:"1",   name:"Kinh doanh",  category:"kinh-doanh",  navbar:"1", home:"1", catgegory:"1"},
    {stt:"4",   name:"Thế giới",    category:"the-gioi",    navbar:"1", home:"1", catgegory:"1"},
    {stt:"5",   name:"Pháp luật",   category:"phap-luat",   navbar:"1", home:"1", catgegory:"1"},
    {stt:"6",   name:"Khoa học",    category:"khoa-hoc",    navbar:"1", home:"1", catgegory:"1"},
    {stt:"7",   name:"Đời sống",     category:"doi-song",     navbar:"1", home:"1", catgegory:"1"},
]