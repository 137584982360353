import React, { useState, useEffect, useRef } from 'react';
import '../../styles/StyleNews.css';
import axios from 'axios';
import { httpss } from '../hosts';
import dataNavbar from '../dataNavbar';
import dataNavbar2 from '../PC/dataNavbar_pc';
import { Link } from 'react-router-dom';
import { decodeHtmlEntities } from '../htmlCodeToDecode';
import { FaRegClock, FaHeadphonesSimple  } from "react-icons/fa6";
import { TfiArrowTopRight } from "react-icons/tfi";
import { VscBroadcast } from "react-icons/vsc";
import vhu from '../../assets/images/vhu.png'
import heb from '../../assets/images/heb.png'
import hea from '../../assets/images/hea.png'
import het from '../../assets/images/het.png'
import heh from '../../assets/images/heh.png'
import happy_food from '../../assets/images/happy-food.png'
import shop1 from '../../assets/images/shop1.png'
import shop2 from '../../assets/images/shop2.png'
import shop3 from '../../assets/images/shop3.png'
import shop4 from '../../assets/images/shop4.png'
import shop5 from '../../assets/images/shop5.png'
import shop6 from '../../assets/images/shop6.png'
import logo from '../../assets/images/logo.png'

export default function HomePC() {
const [dataNews, setDataNews] = useState([]);
const [dataPublish, setDataPublish] = useState([]);
const containerRef = useRef(null);

const [arr_image, setArr_image] = useState([
    shop1, shop2, shop3, shop4, shop5, shop6
])

const getDataNews = async () => {
  try {
  const res = await axios.get(`${httpss}api/news?limit=200`)
  setDataNews(res.data)

  } catch (error) {
      console.error('Lỗi:', error);
  }
};

useEffect(() => {
    getDataNews();
    localStorage.setItem('UrlBack', window.location.pathname);
    handleScrollPosition();
}, []);

// Hàm tự động nhóm các bài viết theo thể loại
function groupByCategory(articles) {
  return articles.reduce((acc, article) => {
    const { category } = article;

    // Nếu chưa có danh sách cho thể loại này, tạo một danh sách mới
    if (!acc[category]) {
      acc[category] = [];
    }

    // Thêm bài viết vào danh sách của thể loại tương ứng
    acc[category].push(article);
    return acc;
  }, {});
}

useEffect(()=>{
  const handleCategory = ()=>{

      const categorizedArticles = groupByCategory(dataNews);
      
      let arr = []
      Object.keys(categorizedArticles).forEach(category => {
          // console.log(`${category}`);
          // console.log(categorizedArticles[category]);

          arr.push({[category]: categorizedArticles[category]})
      });
      setDataPublish(arr)
  }
  handleCategory()
},[dataNews])

// useEffect(()=>{
//   const scrollToTop = () => {
//       window.scrollTo({
//         top: 0,
//         behavior: 'smooth'
//       });
//   };
//   scrollToTop()
// })

const getCurrentDate = () => {
    const today = new Date();

    // Lấy thứ trong tuần (0-6: Chủ Nhật đến Bảy)
    const dayOfWeekIndex = today.getDay(); // 0: Chủ Nhật, 1: Thứ Hai, ..., 6: Thứ Bảy

    // Mảng chứa tên thứ bằng tiếng Việt
    const daysOfWeekVietnamese = [
        'Chủ Nhật',
        'Thứ Hai',
        'Thứ Ba',
        'Thứ Tư',
        'Thứ Năm',
        'Thứ Sáu',
        'Thứ Bảy',
    ];

    // Lấy thứ bằng tiếng Việt
    const dayOfWeek = daysOfWeekVietnamese[dayOfWeekIndex];
    const day = today.getDate(); // Ngày
    const month = today.getMonth() + 1; // Tháng (0-11, nên cộng thêm 1)
    const year = today.getFullYear(); // Năm

    return `${dayOfWeek}, ngày ${day}-${month}-${year}`;
};

function handleChange(e){
    var main = document.getElementById("main");
    main.src = e.target.src;
}

const scrollLeft = () => {
    const container = document.getElementById('slideContainer');
    const scrollAmount = 410;
    container.scrollLeft -= scrollAmount;
};
  
const scrollRight = () => {
    const container = document.getElementById('slideContainer');
    const scrollAmount = 410; 
    if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.scrollLeft = 0;
    } else {
        container.scrollLeft += scrollAmount;
    }
};

const scrollLeft2 = () => {
    const container = document.getElementById('slideContainer2');
    const scrollAmount = 410;
    container.scrollLeft -= scrollAmount;
};
  
const scrollRight2 = () => {
    const container = document.getElementById('slideContainer2');
    const scrollAmount = 410; 
    if (container.scrollLeft + container.clientWidth >= container.scrollWidth) {
        container.scrollLeft = 0;
    } else {
        container.scrollLeft += scrollAmount;
    }
};


useEffect(() => {
    const intervalId = setInterval(() => {
        scrollRight()
    }, 3000)

    // const intervalId2 = setInterval(() => {
    //     scrollRight2()
    // }, 3100)

    return () => {
        clearInterval(intervalId)
        // clearInterval(intervalId2)
    }
}, []);

const handleSaveScroll = () =>{
    sessionStorage.setItem("scrollPosition", window.pageYOffset);
}
const handleScrollPosition = () => {
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    if (scrollPosition) {
        setTimeout(() => {
            window.scrollTo({top: parseInt(scrollPosition),behavior: 'smooth'});
        }, 400);
        sessionStorage.removeItem("scrollPosition");
    }
};
  

// console.log(dataPublish)

  return (
    <div className='w-[1200px] mx-auto mt-[20px]'>
        <div className='w-full flex justify-between'>
            <div className='w-[25%] h-[430px]'>
                <p className='mb-[30px] font-bold'>{getCurrentDate()}</p>            
                {dataPublish.map((data, i) => {
                    return Object.keys(data).map(key => {
                    const articles = data[key]
                    return (
                        <div key={i}>
                            {dataNavbar.map((n, index) => {
                                return articles.map((article, articleIndex) => {
                                if (article.category === n.category) {
                                    if(article.category === 'tin-moi' && articleIndex < 4){
                                        if(articleIndex < 4){
                                            return (
                                                // <Link to={`/detail_pc/${article.id}`} key={articleIndex}>
                                                <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                                    <div className='border-b-[1px] border-[#bbb] mb-[10px]'>
                                                        <p className='my-[20px]'>{decodeHtmlEntities(article.title).slice(0,80)}....</p>
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    }
                                }
                                return null
                                })
                            })}
                        </div>
                    )
                    })
                })}
            </div>

            <div className='w-[72%]'>
                <div className='w-full flex justify-between'>
                    <div className='w-[49%]'>
                        {dataPublish.map((data, i) => {
                            return Object.keys(data).map(key => {
                            const articles = data[key]
                            return (
                                <div key={i}>
                                    {dataNavbar.map((n, index) => {
                                        return articles.map((article, articleIndex) => {
                                        if (article.category === n.category) {
                                            if(article.category === 'tin-moi'){
                                                if(articleIndex === 4){
                                                    return (
                                                        <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                                            <div className='border-b-[1px] border-[#bbb] mb-[20px]'>
                                                                <img src={article.feature} className='w-full h-[200px] object-cover' />
                                                            </div>
                                                        </Link>
                                                    )
                                                }
                                            }
                                        }
                                        return null
                                        })
                                    })}
                                </div>
                            )
                            })
                        })}
                    </div>

                    <div className='w-[49%]'>
                        {dataPublish.map((data, i) => {
                            return Object.keys(data).map(key => {
                            const articles = data[key]
                            return (
                                <div key={i}>
                                    {dataNavbar.map((n, index) => {
                                        return articles.map((article, articleIndex) => {
                                        if (article.category === n.category) {
                                            if(article.category === 'tin-moi'){
                                                if(articleIndex === 4){
                                                    return (
                                                        <Link to={article.link} key={articleIndex}>
                                                            <div className='mb-[20px]'>
                                                                <p className='text-[18px]'><strong>{decodeHtmlEntities(article.title).slice(0,55)}....</strong></p>
                                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description).slice(0,250)}....</p>
                                                            </div>
                                                        </Link>
                                                    )
                                                }
                                            }
                                        }
                                        return null
                                        })
                                    })}
                                </div>
                            )
                            })
                        })}
                    </div>
                </div>

                <div className='w-full'>
                    {dataPublish.map((data, i) => {
                        return Object.keys(data).map(key => {
                        const articles = data[key]
                        return (
                            <div key={i} className='flex justify-between w-full'>
                                {dataNavbar.map((n, index) => {
                                    return articles.map((article, articleIndex) => {
                                    if (article.category === n.category) {
                                        if(article.category === 'tin-moi'){
                                            if(articleIndex >= 5 && articleIndex <= 8){
                                                return (
                                                    <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                                        <div className='mb-[20px] w-[200px]'>
                                                            <img src={article.feature} className='w-full h-[100px] object-cover' />
                                                            <p className='mt-[15px]'><strong>{decodeHtmlEntities(article.title).slice(0,60)}....</strong></p>
                                                        </div>
                                                    </Link>
                                                )
                                            }
                                        }
                                    }
                                    return null
                                    })
                                })}
                            </div>
                        )
                        })
                    })}
                </div>
            </div>

            {/* <div className='w-[25%]'>
                {dataPublish.map((data, i) => {
                    return Object.keys(data).map(key => {
                    const articles = data[key]
                    return (
                        <div key={i}>
                            {dataNavbar.map((n, index) => {
                                return articles.map((article, articleIndex) => {
                                if (article.category === n.category) {
                                    if(article.category === 'tin-moi'){
                                        if(articleIndex >= 8 && articleIndex <= 9){
                                            return (
                                                <Link to={`/detail_pc/${article.id}`} key={articleIndex}>
                                                    <div className='border-b-[1px] border-[#bbb] mb-[20px]'>
                                                        <img src={article.feature} className='w-full h-[200px] object-cover' />
                                                    </div>
                                                </Link>
                                            )
                                        }
                                    }
                                }
                                return null
                                })
                            })}
                        </div>
                    )
                    })
                })}
            </div> */}
        </div>

        <div className='flex justify-center border-y-[1px] border-[#ccc] py-[10px]'>
            <div className='flex'>
                <div className='w-[150px] py-[5px] border-[1px] border-[#bbb] rounded-md flex items-center justify-center bg-gradient-to-b from-[#f8f8f8] to-gray-300 cursor-pointer'>
                    <FaRegClock  size={'30px'} color='#DD0000' />
                    <Link to={`/categories/tin-moi`}>
                        <p className='ml-[10px]'><strong>Tin mới nhất</strong></p>
                    </Link>
                </div>

                <div className='border-l-[2px] border-[#bbb] ml-[20px] flex items-center'>
                    <TfiArrowTopRight size={'25px'} color='#DD0000' className='ml-[10px]' />
                </div>

                <div className='flex items-center ml-[20px]'>
                    {
                        dataNavbar.map((el,i)=>{
                            if(el.category==='phap-luat' || el.category==='cong-nghe' || el.category==='suc-khoe' || el.category==='kinh-doanh'){
                                return <Link to={`/categories/${el.category}`}>
                                    <p className='font-bold bg-[#eee] w-[100px] text-center py-[5px] mx-[10px] rounded-md cursor-pointer'>{el.name}</p>
                                </Link>
                            }
                        })
                    }
                </div>
            </div>

            <div className='flex items-center ml-[20px] text-[#999] border-[1px] border-[#bbb] rounded-md p-[6px] cursor-pointer'>
                <FaHeadphonesSimple size={'20px'} />
                <p className='mx-[5px]'>Hotline</p>
                |
                <VscBroadcast size={'20px'} className='ml-[5px]'/>
                <p className='ml-[5px]'>Quảng cáo</p>
            </div>
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://harmony.hunghau.vn/tin-tuc-su-kien/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>TIN TỨC HHH SG</p>
                </Link>
            </div>

            {/* <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div> */}

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'kinh-doanh' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'kinh-doanh' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`/categories/thoi-su`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>THỜI SỰ</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'thoi-su' && articleIndex < 2){
                                    return (
                                        <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'thoi-su' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://nongsanviet.nongnghiep.vn/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>NÔNG NGHIỆP</p>
                </Link>
            </div>

            {/* <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div> */}

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'van-hoa' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'van-hoa' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://petrovietnam.petrotimes.vn/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>XĂNG DẦU</p>
                </Link>
            </div>

            {/* <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div> */}

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'tin-moi' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'tin-mơi' && articleIndex >=5 && articleIndex < 8){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`/categories/giao-duc`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>GIÁO DỤC</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'giao-duc' && articleIndex < 2){
                                    return (
                                        <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'giao-duc' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://baodautu.vn/dau-tu-d2/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>ĐẦU TƯ</p>
                </Link>
            </div>

            {/* <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div> */}

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'phap-luat' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'phap-luat' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://vneconomy.vn/tai-chinh.htm`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>TÀI CHÍNH</p>
                </Link>
            </div>

            {/* <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div> */}

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'khoa-hoc' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'khoa-hoc' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link onClick={handleSaveScroll} to={`https://baodautu.vn/batdongsan/`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>DỰ ÁN</p>
                </Link>
            </div>

            {/* <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div> */}

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'kinh-doanh' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'kinh-doanh' && articleIndex >=5 && articleIndex < 8){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        {/* <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link to={`/categories/giao-duc`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px]'>GIÁO DỤC</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'giao-duc' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'giao-duc' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link to={`/categories/kinh-doanh`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>KINH DOANH</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'kinh-doanh' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'kinh-doanh' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link to={`/categories/phap-luat`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>PHÁP LUẬT</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'phap-luat' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'phap-luat' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link to={`/categories/the-thao`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>THỂ THAO</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'the-thao' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'the-thao' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link to={`/categories/kinh-doanh`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>CÔNG NGHỆ</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'cong-nghe' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'cong-nghe' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link to={`/categories/suc-khoe`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>SỨC KHỎE</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'suc-khoe' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'suc-khoe' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='mt-[20px]'>
            <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
                <Link to={`/categories/van-hoa`}>
                    <p className='mx-[5px] font-bold mt-[3px] text-[20px] cursor-pointer'>VĂN HÓA</p>
                </Link>
            </div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'van-hoa' && articleIndex < 2){
                                    return (
                                        <Link to={article.link} key={articleIndex}>
                                            <div className='w-[590px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[350px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p>
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb] border-dashed'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className=''>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'van-hoa' && articleIndex >=2 && articleIndex < 5){
                                    return (
                                        <ul className='list-disc pl-5' key={articleIndex}>
                                            <Link to={article.link} key={articleIndex}>
                                                <li className='py-[5px] text-justify'><strong>{decodeHtmlEntities(article.title)}</strong></li>
                                            </Link>
                                        </ul>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div> */}

        <div className="relative w-full bg-[#f5faf7] px-[30px] py-[20px] rounded-md mt-[20px]">
            <img src={logo} className='w-[120px] mx-auto' />
            <p className='font-bold text-center text-[20px] my-[5px]'>DEAL HOT TẠI MYU SHOP</p>

            {/* Nút trượt trái */}
            <button 
                onClick={() => scrollLeft()}
                className="absolute left-0 top-1/2 transform -translate-y-[-50px] text-[25px] p-2 z-10"
            >
                &#10094;
            </button>

            {/* Container chứa slide */}
            <div ref={containerRef} id="slideContainer" className="w-full overflow-x-hidden scroll-smooth mt-[30px]">
                <div className="flex w-max">
                    {
                        arr_image.map((el,i)=>{
                            if(i===2){
                                return <Link to={`https://myu.vn/shop/danh-muc?rootCategoryId=34`} key={i}>
                                    <img src={el} className='w-[400px] h-[210px] mx-[10px] object-fill' />
                                </Link>
                            }
                            else if(i===4){
                                return <Link to={`https://myu.vn/shop`} key={i}>
                                    <img src={el} className='w-[400px] h-[210px] mx-[10px] object-fill' />
                                </Link>
                            }
                            else if(i===5){
                                return <Link to={`https://myu.vn/shop/danh-muc?rootCategoryId=4`} key={i}>
                                    <img src={el} className='w-[400px] h-[210px] mx-[10px] object-fill' />
                                </Link>
                            }
                            else {
                                return <Link to={`https://myu.vn/`} key={i}>
                                    <img src={el} className='w-[400px] h-[210px] mx-[10px] object-fill' />
                                </Link>
                            }
                        })
                    }
                </div>
            </div>

            {/* Nút trượt phải */}
            <button 
                onClick={() => scrollRight()}
                className="absolute right-0 top-1/2 transform -translate-y-[-50px] text-[25px] p-2 z-10"
            >
                &#10095;
            </button>
        </div>

        <div className='mt-[20px] bg-[#f5faf7] rounded-md p-[10px]'>
            <div className='flex justify-between'>
                <p className='mx-[5px] font-bold mt-[3px] mb-[10px] text-[25px]'>Tin thế giới</p>
                <Link onClick={handleSaveScroll} to={`/categories/the-gioi`}>
                    <p>Xem tất cả</p>
                </Link>
            </div>
            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'the-gioi' && articleIndex < 4){
                                    return (
                                        <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                            <div className='w-[280px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[190px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                {/* <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p> */}
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}

            <div className='w-full my-[10px] border-t-[1px] border-[#bbb]'></div>

            {dataPublish.map((data, i) => {
                return Object.keys(data).map(key => {
                const articles = data[key]
                return (
                    <div key={i} className='flex justify-between mt-[2px]'>
                        {dataNavbar.map((n, index) => {
                            return articles.map((article, articleIndex) => {
                            if (article.category === n.category) {
                                if(article.category === 'the-gioi' && articleIndex >= 4 && articleIndex < 8){
                                    return (
                                        <Link onClick={handleSaveScroll} to={article.link} key={articleIndex}>
                                            <div className='w-[280px] mb-[10px]'>
                                                <img src={article.feature} className='w-full h-[190px] object-cover' />
                                                <p className='mt-[20px] text-[20px]'><strong>{decodeHtmlEntities(article.title)}</strong></p>
                                                {/* <p className='text-justify my-[10px]'>{decodeHtmlEntities(article.description)}</p> */}
                                            </div>
                                        </Link>
                                    )
                                }
                            }
                            return null
                            })
                        })}
                    </div>
                )
                })
            })}
        </div>

        <div className='w-full h-[130px] bg-[#f5faf7] rounded-md p-[10px] my-[20px]'>
            {/* <div className='w-full border-l-[2px] border-l-red-600 flex items-center my-[10px] mt-[5px]'>
            </div> */}
            <p className='mx-[5px] font-bold mt-[3px] text-[25px]'>Video HHH SG</p>
            <p className='text-center text-[#bbb] text-[35px]'>COMING SOON</p>
        </div>

        <div className='w-[1200px] flex flex-wrap items-center mx-auto mt-[0px] py-[15px] bg-white'>
            {/* <MdHome size={'28px'} color='#53a84a' className='cursor-pointer' /> */}
            <ul className='flex flex-wrap items-center'>
                {
                    dataNavbar2.map((el,i)=>{
                        if(el.name==='Tin tức HHH SG'){
                            return <Link onClick={handleSaveScroll} to={`https://harmony.hunghau.vn/tin-tuc-su-kien/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Nông nghiệp'){
                            return <Link onClick={handleSaveScroll} to={`https://nongsanviet.nongnghiep.vn/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Xăng dầu'){
                            return <Link onClick={handleSaveScroll} to={`https://petrovietnam.petrotimes.vn/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Đầu tư'){
                            return <Link onClick={handleSaveScroll} to={`https://baodautu.vn/dau-tu-d2/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Tài chính'){
                            return <Link onClick={handleSaveScroll} to={`https://vneconomy.vn/tai-chinh.htm`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else if(el.name==='Dự án'){
                            return <Link onClick={handleSaveScroll} to={`https://baodautu.vn/batdongsan/`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        else {
                            return <Link onClick={handleSaveScroll} to={`/categories/${el.category}`} key={i}>
                                <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                            </Link>
                        }
                        
                        // if(el.name!=='Tin tức HHH SG'){
                        //     return <Link onClick={handleSaveScroll} to={`/categories/${el.category}`} key={i}>
                        //         <li className={`${i===0?'bg-[#53a84a] text-white hover:bg-[#fff] hover:text-[#53a84a] hover:border-[1px] hover:border-[#53a84a] px-[10px] py-[3px] rounded-md':''} mx-[9px] text-[14px] font-bold cursor-pointer hover:text-[#53a84a]`}>{el.name}</li>
                        //     </Link>
                        // }
                        // else {
                        //     return <Link onClick={handleSaveScroll} to={`https://harmony.hunghau.vn/tin-tuc-su-kien/`} key={i}>
                        //         <li className={`${i===0?'bg-[#53a84a] text-white px-[10px] py-[3px] rounded-md':''} mx-[8px] font-bold cursor-pointer text-[14px] hover:text-[#53a84a]`}>{el.name}</li>
                        //     </Link>
                        // }
                    })
                }
            </ul>
        </div>
    </div>
  )
}
